import React, { useRef, useEffect, useState } from "react"
import styles from "@/styles/footer.module.scss"
import Sizing from "./Sizing"
import { Link } from "gatsby"
import Spacing from "./Spacing"
import Form from "./Form.es"
import logo from "@/images/logo-sq-sml.png"
import formStyles from "@/styles/footer-form.module.scss"

const Footer = () => {
    const [success, setSuccess] = useState(false)

    const formOrSuccess = () => {
        if (!success) {
            return (
                <Form
                    type="footer"
                    styles={formStyles}
                    triggerSuccess={() => {
                        setSuccess(true)
                    }}
                ></Form>
            )
        } else {
            return (
                <div>
                    <span className={styles.heading + " h4"}>
                        Gracias por suscribirte a Free Markets Destroy.
                    </span>
                    <span className={styles.copy}>
                        Pronto tendrá noticias nuestras.
                    </span>
                </div>
            )
        }
    }
    return (
        <div className={styles.main}>
            <Sizing
                breaks={[
                    {
                        min: 0,
                        val: {
                            paddingLeft: 1,
                            paddingRight: 1,
                            paddingTop: 2,
                            paddingBottom: 2,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            paddingLeft: 1,
                            paddingRight: 1,
                            paddingTop: 1,
                            paddingBottom: 1,
                        },
                    },
                ]}
            >
                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginBottom: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                float: "right",
                                width: 11,
                                marginBottom: 0.5,
                            },
                        },
                    ]}
                >
                    <p className={"copy copy--white"}>
                        Un proyecto de <br />
                        <b>WASHINGTON POLICY CENTER</b>
                    </p>
                </Sizing>

                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {},
                        },
                        {
                            min: 1024,
                            val: {
                                float: "left",
                                width: 11,
                            },
                        },
                    ]}
                >
                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    display: "none",
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    float: "left",
                                    width: 3.5,
                                    display: "block",
                                },
                            },
                        ]}
                    >
                        <img
                            style={{
                                width: "100%",
                                transform: "translate3d(0 , 0,1px)",
                            }}
                            src={logo}
                            alt=""
                        />
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {},
                            },
                            {
                                min: 1024,
                                val: {
                                    float: "left",
                                    width: 10.5,
                                    marginTop: 0.5,
                                },
                            },
                        ]}
                    >
                        <Sizing
                            breaks={[
                                {
                                    min: 0,
                                    val: {},
                                },
                                {
                                    min: 1024,
                                    val: {
                                        float: "left",
                                        marginBottom: 0.5,
                                        width: "100%",
                                    },
                                },
                            ]}
                        >
                            <span className={"copy copy--white"}>
                                {success ? "¡Éxito!" : "Mantenerse al día"}
                            </span>
                        </Sizing>

                        <div>
                            <div>{formOrSuccess()}</div>
                        </div>
                    </Sizing>
                </Sizing>

                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: { display: "block" },
                        },
                        {
                            min: 1024,
                            val: {
                                float: "right",
                                clear: "right",
                                width: 11,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                                justifyContent: "space-between",
                            },
                        },
                    ]}
                >
                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginTop: 3,
                                    float: "left",
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    float: "left",
                                    marginBottom: 0,
                                    // clear: "right",
                                    marginTop: 0,
                                    width: 7,
                                },
                            },
                        ]}
                    >
                        <a
                            target="_blank"
                            href="https://www.washingtonpolicy.org/marketsimproveeverything"
                            className={styles.link + " " + styles.arrow}
                        >
                            Los mercados lo mejoran todo <span>Blog</span>
                        </a>
                        <a target="_blank"
                           href="https://store.freemarketsdestroy.com"
                           className={styles.link + " " + styles.arrow}>
                            Mercancía de Free Markets <span>Destroy</span>
                        </a>
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {},
                            },
                            {
                                min: 1024,
                                val: {
                                    float: "right",
                                    width: 3,
                                },
                            },
                        ]}
                    >
                        <Sizing
                            breaks={[
                                {
                                    min: 0,
                                    val: { width: 14, float: "left" },
                                },
                                {
                                    min: 1024,
                                    val: { width: "auto", float: "none" },
                                },
                            ]}
                        >
                            <div className={styles.follow}>
                                Sigue Free Markets Destroy en redes sociales
                            </div>
                        </Sizing>
                        <div className={styles.share}>
                            <a
                                target="_blank"
                                href="https://www.facebook.com/freemarketsdestroy/"
                                className={styles.fb}
                            ></a>
                            <a
                                target="_blank"
                                href="https://www.instagram.com/freemarketsdestroy/"
                                className={styles.inst}
                            ></a>
                        </div>
                    </Sizing>
                </Sizing>
                <Spacing space={0}></Spacing>
            </Sizing>

            <div className={styles.sub}>
                <Sizing
                    breaks={[
                        // {
                        //     min: 0,
                        //     val: {},
                        // },
                        {
                            min: 0,
                            val: {
                                float: "left",
                                width: 22,
                                marginLeft: 1,
                                height: "100%",
                            },
                        },
                    ]}
                >
                    <div className={styles.subtext}>
                        <span>
                            Nada aquí debe interpretarse como un intento de ayudar u obstaculizar la aprobación de cualquier legislación ante cualquier cuerpo legislativo.
                        </span>

                        <span>
                            Free Markets Destroy es un proyecto del
                            <a
                                target="_blank"
                                href="https://www.washingtonpolicy.org/centers/detail/wpc-en-espanol"
                            >
                                {" "}
                                Washington Policy Center
                            </a>{" "}
                            | <Link to="/es/privacy-policy">Política de privacidad</Link>
                        </span>
                    </div>
                </Sizing>
            </div>
        </div>
    )
}

export default Footer
