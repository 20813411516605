import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import Sizing from "@/components/general/Sizing"
import Spacing from "@/components/general/Spacing"
import SEO from "@/components/seo"
import styles from "@/styles/about.module.scss"
import Footer from "@/components/general/Footer.es"
import logo from "@/images/logo-sq_spanish.png"
import shareImg from "@/images/og/og_share_spanish.jpg"

const AboutPage = ({pageContext}) => {
    return (
        <>
            <SEO
                title="Acerca Free Markets Destroy | Un Proyecto de Washington Policy Center"
                description="Free Markets Destroy celebra el poder de los mercados libres para abordar los mayores desafíos de la humanidad. Sea parte de la solución aprovechando el poder de los mercados para mejorarlo todo."
                img={shareImg}
                lang="es"
            />

            <div className={styles.main}>
                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 22,
                                float: "left",
                                marginLeft: 1,
                                marginTop: 2,
                                marginBottom: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: 14,
                                float: "left",
                                marginLeft: 5,
                                marginTop: 2,
                                marginBottom: 4,
                            },
                        },
                    ]}
                >
                    <h1 className={styles.title + " h2"}>Acerca</h1>
                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {},
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 10,
                                    float: "left",
                                },
                            },
                        ]}
                    >
                        <img
                            style={{
                                width: "100%",
                                transform: "translate3d(0 , 0,1px)",
                            }}
                            src={logo}
                            alt=""
                        />
                        <p className="copy">
                            Un Proyecto del <b>Washington Policy Center</b>
                        </p>
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    float: "left",
                                    marginTop: 2,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 14,
                                    float: "left",
                                    marginTop: 2,
                                },
                            },
                        ]}
                    >
                        <span className={"copy--lead"}>
                            Acerca de Free Markets Destroy
                        </span>
                        <p className="copy">
                            Free Markets Destroy celebra el poder de los mercados libres para hacer frente a los desafíos más abrumadores de la humanidad. El mundo no es perfecto, pero mejora cada día gracias a los emprendedores que trabajan incansablemente para ofrecer innovaciones que cambian vidas. Sea parte de la solución aprovechando el poder de los mercados para mejorarlo todo.
                        </p>
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: { float: "left", marginTop: 2 },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 14,
                                    float: "left",
                                    marginTop: 2,
                                },
                            },
                        ]}
                    >
                        <span className={"copy--lead"}>
                            Acerca del Washington Policy Center
                        </span>
                        <p className="copy">
                            <a
                                href={"https://www.washingtonpolicy.org/centers/detail/wpc-en-espanol"}
                                target="_blank"
                                style={{
                                    backgroundColor: "#BCE0DB",
                                    display: "inline-block",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                            >
                                Washington Policy Center
                            </a>{" "}
                            es una de las principales organizaciones estatales de investigación de políticas públicas de libre mercado del país. Con sede en Seattle y oficinas ubicadas en todo el estado de Washington, Washington Policy Center es no partidista y sin fines de lucro, y publica estudios, patrocina eventos y educa a los ciudadanos sobre temas vitales de política pública. Las ideas respaldadas por investigaciones sólidas y promovidas a través de publicaciones, eventos y los medios de comunicación, a lo largo del tiempo, crean un entorno en el que los responsables de la formulación de políticas y los ciudadanos toman decisiones acertadas en materia de políticas públicas. Washington Policy Center se centra en ocho áreas de investigación clave: agricultura, educación, medio ambiente, reforma gubernamental, atención médica, pequeñas empresas, transporte y derechos de los trabajadores. Además, el programa de Jóvenes Profesionales de WPC se enfoca en educar, involucrar y empoderar a la próxima generación de líderes del libre mercado en el estado de Washington.
                        </p>
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: { float: "left", marginTop: 2 },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 14,
                                    float: "left",
                                    marginTop: 2,
                                },
                            },
                        ]}
                    >
                        <span className={"copy--lead"}>
                            Creatividad, redacción y estrategia
                        </span>
                        <p className="copy">
                            <a
                                href={"https://eo.foundation/"}
                                target="_blank"
                                style={{
                                    backgroundColor: "#D7C2DA",
                                    display: "inline-block",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                            >
                                Emergent Order
                            </a>{" "}
                            es un estudio creativo que transforma grandes ideas en oportunidades innovadoras. Aprovechamos las tendencias de los medios, la tecnología y la estrecha colaboración con nuestros socios para crear historias memorables en cualquier plataforma. No trabajamos en el retenedor. No tenemos un equipo B. Y no ofrecemos "soluciones" estándar y con plantillas para marketing o medios. Para resolver los problemas de nuestros socios, diseñamos y producimos estrategias personalizadas e historias convincentes.
                        </p>
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: { float: "left", marginTop: 2 },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 6,
                                    float: "left",
                                    marginTop: 2,
                                },
                            },
                        ]}
                    >
                        <span className={"copy--lead"}>
                            Desarrollo de diseño
                        </span>
                        <p className="copy">
                            <a
                                href={"https://gladeye.com/"}
                                target="_blank"
                                style={{
                                    backgroundColor: "#EAAEA4",
                                    display: "inline-block",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                            >
                                Gladeye
                            </a>{" "}
                            es una agencia de innovación digital en Auckland, Nueva Zelanda y Nueva York.
                        </p>
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: { float: "left", marginTop: 2 },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 6,
                                    float: "left",
                                    marginTop: 2,
                                    marginLeft: 2,
                                },
                            },
                        ]}
                    >
                        <span className={"copy--lead"}>Ilustraciones</span>
                        <p className="copy">
                            <a
                                target="_blank"
                                href={"https://sugacyan.com/"}
                                style={{
                                    backgroundColor: "#A4CBEB",
                                    display: "inline-block",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                            >
                                Pablo Espinosa
                            </a>{" "}
                            es un ilustrador mexicano que vive y trabaja en Tokio, Japón.
                        </p>
                    </Sizing>
                </Sizing>

                <Spacing space={0}></Spacing>
            </div>
            <Footer></Footer>
        </>
    )
}

export default AboutPage
